var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "title": "Informasi Produk"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loadingInfoBarang
    }
  }, [_c('b-card-actions', {
    attrs: {
      "no-actions": true
    }
  }, [_c('validation-observer', {
    ref: "form"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v(" Informasi Produk ")])])], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nama Produk",
      "label-for": ""
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nama",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "placeholder": "Ex: Sarung Tangan Medis"
          },
          model: {
            value: _vm.form.nama,
            callback: function ($$v) {
              _vm.$set(_vm.form, "nama", $$v);
            },
            expression: "form.nama"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipe Produk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "product_type",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-select', {
          attrs: {
            "disabled": "",
            "options": [{
              value: 'barang',
              text: 'Barang'
            }, {
              value: 'jasa',
              text: 'Jasa'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.product_type,
            callback: function ($$v) {
              _vm.$set(_vm.form, "product_type", $$v);
            },
            expression: "form.product_type"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kategori"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kategori",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref3) {
        var errors = _ref3.errors;
        return [_c('b-input-group', [_c('v-select', {
          attrs: {
            "options": _vm.kategoriOptions,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text",
            "placeholder": "Silakan Pilih"
          },
          model: {
            value: _vm.form.id_kategori,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id_kategori", $$v);
            },
            expression: "form.id_kategori"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "variant": "outline-success"
          },
          on: {
            "click": function ($event) {
              _vm.showModalKategori = true;
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon"
          }
        })], 1)], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah Kategori"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submitKategori
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              _vm.showModalKategori = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalKategori,
      callback: function ($$v) {
        _vm.showModalKategori = $$v;
      },
      expression: "showModalKategori"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "kategori"
    }
  }, [_vm._v("Kategori")]), _c('b-form-input', {
    attrs: {
      "id": "kategori",
      "placeholder": "Nama kategori"
    },
    model: {
      value: _vm.formkategori,
      callback: function ($$v) {
        _vm.formkategori = $$v;
      },
      expression: "formkategori"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Satuan "
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "satuan",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref4) {
        var errors = _ref4.errors;
        return [_c('b-input-group', [_c('v-select', {
          attrs: {
            "options": _vm.satuanOptions,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function (_ref5) {
              var attributes = _ref5.attributes,
                events = _ref5.events;
              return [_c('input', _vm._g(_vm._b({
                staticClass: "vs__search",
                attrs: {
                  "required": !_vm.form.id_satuan
                }
              }, 'input', attributes, false), events))];
            }
          }], null, true),
          model: {
            value: _vm.form.id_satuan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "id_satuan", $$v);
            },
            expression: "form.id_satuan"
          }
        }), _c('b-input-group-append', [_c('b-button', {
          attrs: {
            "variant": "outline-success"
          },
          on: {
            "click": function ($event) {
              _vm.showModalSatuan = true;
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon"
          }
        })], 1)], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah Satuan"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submitSatuan
          }
        }, [_vm._v(" Tambah ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              _vm.showModalSatuan = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalSatuan,
      callback: function ($$v) {
        _vm.showModalSatuan = $$v;
      },
      expression: "showModalSatuan"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "satuan"
    }
  }, [_vm._v("Satuan")]), _c('b-form-input', {
    attrs: {
      "id": "satuan",
      "placeholder": "Nama satuan"
    },
    model: {
      value: _vm.formsatuan,
      callback: function ($$v) {
        _vm.formsatuan = $$v;
      },
      expression: "formsatuan"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Produk Konsinyasi?"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "is_consignment",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Ya'
            }, {
              value: 0,
              text: 'Tidak'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.is_consignment,
            callback: function ($$v) {
              _vm.$set(_vm.form, "is_consignment", $$v);
            },
            expression: "form.is_consignment"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Kondisi?"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kondisi",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Baru'
            }, {
              value: 2,
              text: 'Bekas'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.kondisi,
            callback: function ($$v) {
              _vm.$set(_vm.form, "kondisi", $$v);
            },
            expression: "form.kondisi"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Deskripsi"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "deskripsi",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-textarea', {
          model: {
            value: _vm.form.deskripsi,
            callback: function ($$v) {
              _vm.$set(_vm.form, "deskripsi", $$v);
            },
            expression: "form.deskripsi"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h2', {
    staticClass: "alert-heading"
  }, [_vm._v(" Varian Produk ")])])], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('div', {
    staticClass: "table table-responsive"
  }, [_c('b-table-simple', {
    staticClass: "table table-bordered table-responsive",
    attrs: {
      "small": ""
    }
  }, [_c('b-thead', [_c('b-tr', [_c('b-th', [_vm._v("Kode")]), _c('b-th', [_vm._v("Varian")]), _c('b-th', [_vm._v("Harga Dasar")]), _c('b-th', [_vm._v("No. Batch")]), _c('b-th', [_vm._v("Expired Date")]), _c('b-th', {
    staticStyle: {
      "width": "3%"
    }
  })], 1)], 1), _c('b-tbody', _vm._l(_vm.form.variants, function (item, i) {
    return _c('b-tr', {
      key: i
    }, [_c('b-td', [_c('b-form-input', {
      attrs: {
        "placeholder": "Ex: 23213",
        "required": ""
      },
      model: {
        value: item.kode,
        callback: function ($$v) {
          _vm.$set(item, "kode", $$v);
        },
        expression: "item.kode"
      }
    })], 1), _c('b-td', [_c('b-form-input', {
      attrs: {
        "placeholder": "Ex: Ukuran XL",
        "required": ""
      },
      model: {
        value: item.varian,
        callback: function ($$v) {
          _vm.$set(item, "varian", $$v);
        },
        expression: "item.varian"
      }
    })], 1), _c('b-td', [_c('b-input-group', {
      staticClass: "input-group-merge",
      attrs: {
        "prepend": "Rp."
      }
    }, [_c('b-form-input', {
      attrs: {
        "placeholder": "Harga Dasar",
        "required": ""
      },
      on: {
        "keyup": function ($event) {
          return _vm.doFormatRupiah(i);
        }
      },
      model: {
        value: item.harga_dasar,
        callback: function ($$v) {
          _vm.$set(item, "harga_dasar", $$v);
        },
        expression: "item.harga_dasar"
      }
    })], 1)], 1), _c('b-td', [_c('b-form-input', {
      attrs: {
        "placeholder": "No. Batch",
        "required": ""
      },
      model: {
        value: item.no_batch,
        callback: function ($$v) {
          _vm.$set(item, "no_batch", $$v);
        },
        expression: "item.no_batch"
      }
    })], 1), _c('b-td', [_c('b-form-input', {
      attrs: {
        "type": "date",
        "placeholder": "Expired Date",
        "required": ""
      },
      model: {
        value: item.expire_date,
        callback: function ($$v) {
          _vm.$set(item, "expire_date", $$v);
        },
        expression: "item.expire_date"
      }
    })], 1), _c('b-td', [i == 0 && !_vm.$route.params.id ? _c('section', [_c('b-button', {
      attrs: {
        "variant": "primary",
        "size": "sm"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.form.variants.push({
            varian: null,
            kode: null,
            harga_dasar: 0,
            no_batch: 0,
            expired_date: null
          });
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "PlusIcon"
      }
    })], 1)], 1) : i > 0 && !_vm.$route.params.id ? _c('section', [_c('b-button', {
      attrs: {
        "variant": "flat-danger",
        "size": "sm"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.removeVar(i);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "XIcon"
      }
    })], 1)], 1) : _vm._e()])], 1);
  }), 1)], 1)], 1)])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Pengiriman (tidak wajib) ")])])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Berat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "berat",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref9) {
        var errors = _ref9.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "gram"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "1000"
          },
          model: {
            value: _vm.form.berat,
            callback: function ($$v) {
              _vm.$set(_vm.form, "berat", $$v);
            },
            expression: "form.berat"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Panjang"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "panjang",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref10) {
        var errors = _ref10.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "cm"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "100"
          },
          model: {
            value: _vm.form.panjang,
            callback: function ($$v) {
              _vm.$set(_vm.form, "panjang", $$v);
            },
            expression: "form.panjang"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lebar"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "lebar",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref11) {
        var errors = _ref11.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "cm"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "10"
          },
          model: {
            value: _vm.form.lebar,
            callback: function ($$v) {
              _vm.$set(_vm.form, "lebar", $$v);
            },
            expression: "form.lebar"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tinggi"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tinggi",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref12) {
        var errors = _ref12.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "cm"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "10"
          },
          model: {
            value: _vm.form.tinggi,
            callback: function ($$v) {
              _vm.$set(_vm.form, "tinggi", $$v);
            },
            expression: "form.tinggi"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Minimal Pesanan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "min_pesanan",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-input', {
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Minimal Pesanan"
          },
          model: {
            value: _vm.form.min_pesanan,
            callback: function ($$v) {
              _vm.$set(_vm.form, "min_pesanan", $$v);
            },
            expression: "form.min_pesanan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Asuransi Pengiriman"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "asuransi_pengiriman",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Wajib'
            }, {
              value: 2,
              text: 'Opsi'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.asuransi_pengiriman,
            callback: function ($$v) {
              _vm.$set(_vm.form, "asuransi_pengiriman", $$v);
            },
            expression: "form.asuransi_pengiriman"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Layanan Ekspedisi"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "layanan_ekspedisi",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref15) {
        var errors = _ref15.errors;
        return [_c('v-select', {
          attrs: {
            "multiple": "",
            "options": _vm.layananEkspedisiOptions,
            "reduce": function (option) {
              return option.value;
            },
            "label": "text"
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function (_ref16) {
              var attributes = _ref16.attributes,
                events = _ref16.events;
              return [_c('input', _vm._g(_vm._b({
                staticClass: "vs__search",
                attrs: {
                  "required": !_vm.form.layanan_ekspedisi
                }
              }, 'input', attributes, false), events))];
            }
          }], null, true),
          model: {
            value: _vm.form.layanan_ekspedisi,
            callback: function ($$v) {
              _vm.$set(_vm.form, "layanan_ekspedisi", $$v);
            },
            expression: "form.layanan_ekspedisi"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Lain-lain ")])])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": _vm.form.is_pre_order == 1 ? '6' : '12',
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Apakah Produk PreOrder?"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "is_pre_order",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref17) {
        var errors = _ref17.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Ya'
            }, {
              value: 0,
              text: 'Tidak'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.is_pre_order,
            callback: function ($$v) {
              _vm.$set(_vm.form, "is_pre_order", $$v);
            },
            expression: "form.is_pre_order"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _vm.form.is_pre_order == 1 ? _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Lama Waktu PreOrder"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "pre_order_day",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref18) {
        var errors = _ref18.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge"
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Lama"
          },
          model: {
            value: _vm.form.pre_order_day,
            callback: function ($$v) {
              _vm.$set(_vm.form, "pre_order_day", $$v);
            },
            expression: "form.pre_order_day"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_vm._v("Hari")])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2092461598)
  })], 1)], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Perlihatkan ke cabang lain?"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "show_to_others",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref19) {
        var errors = _ref19.errors;
        return [_c('b-form-select', {
          attrs: {
            "options": [{
              value: 1,
              text: 'Ya'
            }, {
              value: 0,
              text: 'Tidak'
            }],
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.show_to_others,
            callback: function ($$v) {
              _vm.$set(_vm.form, "show_to_others", $$v);
            },
            expression: "form.show_to_others"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])], 1)], 1)], 1)], 1)], 1), _vm.canManagePrice ? _c('b-tab', {
    attrs: {
      "title": "Pengaturan Harga"
    }
  }, [_c('b-card', [_c('b-table', {
    staticClass: "mb-2",
    attrs: {
      "striped": "",
      "hover": "",
      "bordered": "",
      "fields": [{
        key: 'no',
        label: 'No'
      }, {
        key: 'nama',
        label: 'Jenis Harga'
      }, {
        key: 'harga',
        label: 'Harga'
      }],
      "items": _vm.priceList
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (_ref20) {
        var index = _ref20.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(harga)",
      fn: function (_ref21) {
        var item = _ref21.item;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          attrs: {
            "prepend": "Rp."
          }
        }, [_c('b-form-input', {
          attrs: {
            "state": _vm.errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": "Harga Dasar"
          },
          model: {
            value: item.harga,
            callback: function ($$v) {
              _vm.$set(item, "harga", $$v);
            },
            expression: "item.harga"
          }
        })], 1)];
      }
    }], null, false, 186243545)
  }), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.saveHarga($event);
      }
    }
  }, [_vm._v("Simpan")])], 1)], 1)], 1) : _vm._e(), _vm.canManagePrice ? _c('b-tab', {
    attrs: {
      "title": "Konversi Satuan"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-tambah",
      modifiers: {
        "modal-tambah": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" Tambah ")], 1), _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "cancel-variant": "secondary",
      "ok-title": "Tambah",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Tambah"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.saveKonversi
          }
        }, [_vm._v(" Simpan ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Reset ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              _vm.showModal = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }], null, false, 2246485995),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('b-form', [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "satuan"
    }
  }, [_vm._v("Pilih Satuan yang akan di konversikan")]), _c('b-form-select', {
    attrs: {
      "options": _vm.id_satuan,
      "label": "satuan"
    },
    model: {
      value: _vm.form2.id_satuan,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "id_satuan", $$v);
      },
      expression: "form2.id_satuan"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "nilai"
    }
  }, [_vm._v("Nilai")]), _c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": ""
    }
  }, [_c('b-form-input', {
    attrs: {
      "state": _vm.errors.length > 0 ? false : null,
      "type": "number",
      "placeholder": "Harga Dasar"
    },
    model: {
      value: _vm.form2.nilai,
      callback: function ($$v) {
        _vm.$set(_vm.form2, "nilai", $$v);
      },
      expression: "form2.nilai"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "id": "table2",
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "items": _vm.items,
      "fields": _vm.fields2
    },
    scopedSlots: _vm._u([{
      key: "cell(id_barang)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.getBarangName(data.item)) + " ")];
      }
    }, {
      key: "cell(nilai)",
      fn: function (_ref22) {
        var item = _ref22.item;
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(item.nilai)) + " ")];
      }
    }, {
      key: "cell(no)",
      fn: function (_ref23) {
        var index = _ref23.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Ubah',
            expression: "'Ubah'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Hapus',
            expression: "'Hapus'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          attrs: {
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        })], 1)];
      }
    }], null, false, 1076193426)
  })], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }